<template>
  <t-page>
    <template #header>
      <div>
        <h3>{{ $t('pages.applicant.heading') }}</h3>
        <br>
        <span>{{ $t('pages.applicant.lead') }}</span>
      </div>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Applicant from '~/models/Applicant';
import IndexTable from '~/pages/shared/applicants/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'employee',

  data: () => ({
    query: () => new Applicant().include('user', 'occupation'),

    filters: [],

    fields: ['user', 'occupation', 'created_at'],
  }),
});
</script>
